import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { NewsItemSchema } from '../../shared/schemas';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';

@Injectable()
export class NewsItemApi {
  private endpoint = 'news_items/';

  constructor(private gateway: ApiGateway) {}

  load(params): Observable<any> {
    return this.gateway.get(this.endpoint, { params }).pipe(
      map((res) => reformatListResponse('NewsItem', res)),
      map((data) => normalize(data, arrayOf(NewsItemSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  get(params): Observable<any> {
    return this.gateway.get(this.endpoint, { params, meta: true }).pipe(
      map((res) => ({ data: reformatListResponse('NewsItem', res.data), meta: res.meta })),
      map((data) => ({
          ...normalize(data.data, arrayOf(NewsItemSchema), { assignEntity: assignSchemaEntity }),
          meta: data.meta,
        }))
    );
  }

  add(newsItemData): Observable<any> {
    return this.gateway.post(this.endpoint, newsItemData).pipe(
      map((res) => reformatEntityResponse('NewsItem', res)),
      map((data) => normalize(data, NewsItemSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  update(id, data): Observable<any> {
    return this.gateway.put(this.endpoint + id, data).pipe(
      map((res) => reformatEntityResponse('NewsItem', res)),
      map((data) => normalize(data, NewsItemSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  fetch(newsItemId): Observable<any> {
    return this.gateway.get(this.endpoint + '/' + newsItemId).pipe(
      map((res) => reformatEntityResponse('NewsItem', res)),
      map((data) => normalize(data, NewsItemSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  remove(newsItemId): Observable<any> {
    return this.gateway.delete(this.endpoint + newsItemId);
  }
}
