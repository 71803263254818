import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { toDate } from 'date-fns';
import mapValues from 'lodash-es/mapValues';
import orderBy from 'lodash-es/orderBy';
import some from 'lodash-es/some';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import u from 'updeep';

import { format, parseDate } from '../../../shared/date.helper';
import { getAuthenticatedDepartmentIds } from '../../auth/auth.service';
import { AppState } from '../../index';
import { mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { AttachmentApi } from '../attachment.api';
import { getEmployeeEntities } from '../employee/employee.service';
import { getAuthenticatedUserId } from './../../auth/auth.service';
import { NewsItemAction } from './news-item.action';
import { NewsItemApi } from './news-item.api';
import { NewsItemModel, NewsItemState } from './news-item.model';

@Injectable()
export class NewsItemService {
  constructor(
    private store: Store<AppState>,
    private api: NewsItemApi,
    private attachmentApi: AttachmentApi,
  ) {}

  load(request) {
    this.store.dispatch(NewsItemAction.load());
    return this.api.load(request).pipe(
      tap((response) => this.store.dispatch(NewsItemAction.loadSuccess(response))),
      catchError((response) => {
        this.store.dispatch(NewsItemAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  get(request) {
    this.store.dispatch(NewsItemAction.get());
    return this.api.get(request).pipe(
      tap((response) => this.store.dispatch(NewsItemAction.getSuccess(response))),
      catchError((response) => {
        this.store.dispatch(NewsItemAction.getFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  download(attachmentId: string, fileName: string) {
    return this.attachmentApi.download(attachmentId, fileName);
  }

  add(newsItemData): Observable<any> {
    this.store.dispatch(NewsItemAction.add(newsItemData));

    return this.api.add(newsItemData).pipe(
      tap((response) => this.store.dispatch(NewsItemAction.addSuccess(response))),
      catchError((response) => {
        this.store.dispatch(NewsItemAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  update(id, newsItemData) {
    this.store.dispatch(NewsItemAction.update(newsItemData));

    return this.api.update(id, newsItemData).pipe(
      tap((response) => this.store.dispatch(NewsItemAction.updateSuccess(response))),
      catchError((response) => {
        this.store.dispatch(NewsItemAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  fetch(id) {
    this.store.dispatch(NewsItemAction.fetch(id));

    return this.api.fetch(id).pipe(
      map((response) => {
        this.store.dispatch(NewsItemAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(NewsItemAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  remove(id) {
    this.store.dispatch(NewsItemAction.remove(id));

    return this.api.remove(id).pipe(
      map((response) => {
        this.store.dispatch(NewsItemAction.removeSuccess(id));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(NewsItemAction.removeFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  save(newsItemData) {
    if (newsItemData.NewsItem.id) {
      return this.update(newsItemData.NewsItem.id, newsItemData);
    }

    newsItemData = u({ NewsItem: u.omit('id') }, newsItemData);

    return this.add(newsItemData);
  }

  removeAttachment(attachmentData) {
    this.store.dispatch(NewsItemAction.removeAttachment(attachmentData.news_item_id, attachmentData));

    return this.attachmentApi.delete(attachmentData.id).pipe(
      map((response) => {
        this.store.dispatch(NewsItemAction.removeAttachmentSuccess(attachmentData.news_item_id, attachmentData.id));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(NewsItemAction.removeAttachmentFailed(attachmentData.news_item_id, response));
        return observableThrowError(response);
      }),
    );
  }
}

export const getNewsItemState = (appState: AppState): NewsItemState => appState.orm.newsItems;

export const getNewsItemLoading = compose((state) => state.loading, getNewsItemState);

export const getNewsItemIds = compose((state) => state.items, getNewsItemState);

export const getNewsItemPaginationStatus = compose(
  (state) => ({ currentPage: state.currentPage, pageCount: state.pageCount }),
  getNewsItemState,
);

export const getNewsItemEntities = createSelector(getNewsItemState, getEmployeeEntities, (state, employeeEntities) =>
  mapValues(state.itemsById, (newsItem) => {
    const author = employeeEntities[newsItem.user_id];
    if (!author) {
      return newsItem;
    }

    return {
      ...newsItem,
      Author: author,
    };
  }),
);

export const sortNewsItems = (newsItems: NewsItemModel[]): NewsItemModel[] => orderBy(newsItems, ['created'], ['desc']);
export const mapAndSortNewsItems = mapAndSortEntities(sortNewsItems);

export const getNewsItems = createSelector(getNewsItemIds, getNewsItemEntities, mapAndSortNewsItems);

export const getNewsItem = (id: string) => createSelector(getNewsItemEntities, (entities) => mapEntity(id, entities));

export const getNewsItemsForPeriod = (minDate, maxDate) =>
  createSelector(getNewsItems, (newsItems) =>
    newsItems.filter((newsItem) => {
      const created = format(parseDate(newsItem.created), 'yyyy-MM-dd');
      return created >= minDate && created <= maxDate;
    }),
  );

export const getNewsItemsForAuthenticatedUser = (minDate, maxDate) =>
  createSelector(
    getAuthenticatedUserId,
    getAuthenticatedDepartmentIds,
    getNewsItemsForPeriod(minDate, maxDate),
    (userId, departmentIds, newsItems: NewsItemModel[]) =>
      newsItems.filter((newsItem) => {
        if (newsItem.Author && newsItem.Author.id === userId) {
          return true;
        }

        if (newsItem.Department) {
          return some(newsItem.Department, (departmentId) => departmentIds.includes(departmentId));
        }

        return false;
      }),
  );
