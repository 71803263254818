import { Pipe, PipeTransform } from '@angular/core';

import { DecimalPipe } from './decimal.pipe';

@Pipe({
  name: 'fileSize',
  pure: true,
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  public constructor(private decimalPipe: DecimalPipe) {}

  public transform(value: string | number, precision: number | 'compact' = 2): string {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (!value) return '-';

    if (value / 1000 / 1000 > 1) {
      return this.decimalPipe.transform(value / 1000 / 1000, precision) + ' MB';
    }

    if (value / 1000 > 1) {
      return this.decimalPipe.transform(value / 1000, precision) + ' KB';
    }

    if (value > 1) {
      return value + ' B';
    }
    return '-';
  }
}
