import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[NewsItem]';

export const newsItemActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  GET: `${ACTION_PREFIX} Get`,
  GET_SUCCESS: `${ACTION_PREFIX} Get Success`,
  GET_FAILED: `${ACTION_PREFIX} Get Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,
  REMOVE_FAILED: `${ACTION_PREFIX} Remove Failed`,

  REMOVE_ATTACHMENT: `${ACTION_PREFIX} Remove Attachment`,
  REMOVE_ATTACHMENT_SUCCESS: `${ACTION_PREFIX} Remove Attachment Success`,
  REMOVE_ATTACHMENT_FAILED: `${ACTION_PREFIX} Remove Attachment Failed`,
};

export class NewsItemAction {
  static load(): Action {
    return {
      type: newsItemActionType.LOAD,
    };
  }

  static loadSuccess(newsItem): Action {
    return {
      type: newsItemActionType.LOAD_SUCCESS,
      payload: newsItem,
    };
  }

  static loadFailed(err): Action {
    return {
      type: newsItemActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static get(): Action {
    return {
      type: newsItemActionType.GET,
    };
  }

  static getSuccess(newsItem): Action {
    return {
      type: newsItemActionType.GET_SUCCESS,
      payload: newsItem,
    };
  }

  static getFailed(err): Action {
    return {
      type: newsItemActionType.GET_FAILED,
      payload: err,
    };
  }

  static add(newsItemData): Action {
    return {
      type: newsItemActionType.ADD,
      payload: newsItemData,
    };
  }

  static addSuccess(newsItemResponse): Action {
    return {
      type: newsItemActionType.ADD_SUCCESS,
      payload: newsItemResponse,
    };
  }

  static addFailed(err): Action {
    return {
      type: newsItemActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(newsItemData): Action {
    return {
      type: newsItemActionType.UPDATE,
      payload: newsItemData,
    };
  }

  static updateSuccess(newsItemResponse): Action {
    return {
      type: newsItemActionType.UPDATE_SUCCESS,
      payload: newsItemResponse,
    };
  }

  static updateFailed(err): Action {
    return {
      type: newsItemActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static fetch(id: any) {
    return {
      type: newsItemActionType.FETCH,
      payload: id,
    };
  }

  static fetchSuccess(response) {
    return {
      type: newsItemActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  static fetchFailed(id: string, err) {
    return {
      type: newsItemActionType.FETCH_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  static remove(id: any) {
    return {
      type: newsItemActionType.REMOVE,
      payload: id,
    };
  }

  static removeSuccess(id) {
    return {
      type: newsItemActionType.REMOVE_SUCCESS,
      payload: id,
    };
  }

  static removeFailed(id: string, err) {
    return {
      type: newsItemActionType.REMOVE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  static removeAttachment(newsItemId: any, attachment) {
    return {
      type: newsItemActionType.REMOVE_ATTACHMENT,
      payload: {
        newsItemId,
        attachment,
      },
    };
  }

  static removeAttachmentSuccess(newsItemId, attachmentId) {
    return {
      type: newsItemActionType.REMOVE_ATTACHMENT_SUCCESS,
      payload: {
        newsItemId,
        attachmentId,
      },
    };
  }

  static removeAttachmentFailed(newsItemId: string, err) {
    return {
      type: newsItemActionType.REMOVE_ATTACHMENT_FAILED,
      payload: {
        newsItemId,
        err,
      },
    };
  }
}
